import React, { useEffect, useState, useRef } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { handleApiResponse } from '../utils/apiHandler';

const PaymentAlert = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [verificationComplete, setVerificationComplete] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, userData, login } = useAuth();
  const verificationInProgress = useRef(false);

  useEffect(() => {
    const verifyPayment = async (sessionId) => {
      if (verificationInProgress.current || verificationComplete) return;
      verificationInProgress.current = true;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/payments/verify-session/${sessionId}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
          }
        );

        console.log('Verification response:', response);
        await handleApiResponse(response, logout, navigate);

        if (!response.ok) {
          throw new Error('Failed to verify payment');
        }

        const data = await response.json();
        
        if (data.status === 'success' || data.status === 'already_processed') {
          setMessage(data.message || 'Payment was successfully processed');
          setSeverity('success');
          setOpen(true);
          
          // Refresh user data using the access token
          const token = localStorage.getItem('access_token');
          if (token) {
            await login(token);
          }
          
          // Clear the payment verification parameters
          sessionStorage.removeItem('paymentVerification');
          // Remove query parameters without triggering a re-render
          window.history.replaceState({}, '', location.pathname);
          setVerificationComplete(true);
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        setMessage('Failed to verify payment status. Please contact support if the issue persists.');
        setSeverity('error');
        setOpen(true);
      } finally {
        verificationInProgress.current = false;
      }
    };

    if (!verificationComplete) {
      const params = new URLSearchParams(location.search);
      let payment = params.get('payment');
      let sessionId = params.get('session_id');

      // Check sessionStorage if URL params are not present
      if (!payment || !sessionId) {
        const stored = sessionStorage.getItem('paymentVerification');
        if (stored) {
          const storedData = JSON.parse(stored);
          payment = storedData.payment;
          sessionId = storedData.sessionId;
        }
      }

      if (payment === 'success' && sessionId) {
        console.log('Starting payment verification process');
        verifyPayment(sessionId);
      } else if (payment === 'cancelled' && !verificationComplete) {
        setMessage('Payment was cancelled. Please try again if you want to proceed with the purchase.');
        setSeverity('warning');
        setOpen(true);
        // Remove query parameters without triggering a re-render
        window.history.replaceState({}, '', location.pathname);
        setVerificationComplete(true);
      }
    }
  }, [location, navigate, logout, login, verificationComplete]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar 
      open={open} 
      autoHideDuration={6000} 
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert 
        onClose={handleClose} 
        severity={severity} 
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default PaymentAlert; 