import React, { useEffect, useRef } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import MainLayout from './layout/MainLayout';
import PublicLayout from './layout/PublicLayout';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import OAuthCallback from './components/OAuthCallback';
import DocumentManager from './components/DocumentManager';
import Pricing from './pages/Pricing';
import PrivateRoute from './components/PrivateRoute';
import { SnackbarProvider } from './context/SnackbarContext';
import Transactions from './pages/Transactions';

const App = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const navigationInProgress = useRef(false);

  useEffect(() => {
    const handlePaymentRedirect = () => {
      if (navigationInProgress.current) return;
      
      const params = new URLSearchParams(location.search);
      const payment = params.get('payment');
      const sessionId = params.get('session_id');
      
      if (payment === 'success' && sessionId) {
        // Store the payment verification params
        sessionStorage.setItem('paymentVerification', JSON.stringify({ payment, sessionId }));
        
        if (isAuthenticated) {
          if (location.pathname !== '/dashboard') {
            navigationInProgress.current = true;
            navigate(`/dashboard?payment=${payment}&session_id=${sessionId}`, { replace: true });
          }
        } else {
          if (location.pathname !== '/login') {
            navigationInProgress.current = true;
            sessionStorage.setItem('returnTo', `/dashboard?payment=${payment}&session_id=${sessionId}`);
            navigate('/login', { replace: true });
          }
        }
      } else {
        // Check if we have a stored return URL after login
        const returnTo = sessionStorage.getItem('returnTo');
        if (isAuthenticated && returnTo && location.pathname === '/login') {
          navigationInProgress.current = true;
          sessionStorage.removeItem('returnTo');
          navigate(returnTo, { replace: true });
        }
      }
    };

    handlePaymentRedirect();

    return () => {
      navigationInProgress.current = false;
    };
  }, [location, navigate, isAuthenticated]);

  return (
    <SnackbarProvider>
      <Routes>
        {/* Public routes */}
        <Route element={<PublicLayout />}>
          <Route path="/login" element={
            isAuthenticated ? (
              <Navigate to={sessionStorage.getItem('returnTo') || '/dashboard'} replace />
            ) : (
              <Login />
            )
          } />
          <Route path="/oauth/callback" element={<OAuthCallback />} />
        </Route>

        {/* Protected routes */}
        <Route element={<MainLayout />}>
          <Route path="/dashboard" element={
            isAuthenticated ? <Dashboard /> : <Navigate to="/login" replace />
          } />
          <Route path="/profile" element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />
          <Route path="/" element={
            <Navigate to={isAuthenticated ? "/dashboard" : "/login"} replace />
          } />
          <Route path="/documents" element={<DocumentManager />} />
          <Route path="/pricing" element={
            <PrivateRoute>
              <Pricing />
            </PrivateRoute>
          } />
          <Route path="/transactions" element={
            <PrivateRoute>
              <Transactions />
            </PrivateRoute>
          } />
        </Route>
      </Routes>
    </SnackbarProvider>
  );
};

export default App;