import React, { useState, useEffect, useRef } from 'react';
import html2pdf from 'html2pdf.js';
import { 
  Container, 
  Typography, 
  Box,
  Alert,
  Snackbar,
  Button,
  CircularProgress,
  Paper
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import FileUploadZone from '../components/FileUploadZone';
import PaymentAlert from '../components/PaymentAlert';
import DownloadIcon from '@mui/icons-material/Download';

const Dashboard = () => {
  const location = useLocation();
  const [showProfileAlert, setShowProfileAlert] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [loanApplicationFiles, setLoanApplicationFiles] = useState([]);
  const [loanGuidelinesFiles, setLoanGuidelinesFiles] = useState([]);
  const [error, setError] = useState(null);
  const [analysisResults, setAnalysisResults] = useState(null);
  const analysisResultsRef = useRef(null);
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const payment = params.get('payment');
    const sessionId = params.get('session_id');
    
    console.log('Dashboard URL params:', { payment, sessionId });
    
    if (payment === 'success' && sessionId) {
      console.log('Payment verification needed for session:', sessionId);
    }
  }, [location.search]);
  
  const handleUpload = async () => {
    if (loanApplicationFiles.length === 0 && loanGuidelinesFiles.length === 0) {
      return;
    }

    setIsUploading(true);
    setError(null);

    const formData = new FormData();
    
    if (loanApplicationFiles.length === 1) {
      formData.append('loan_application_files', loanApplicationFiles[0]);
    }
    
    if (loanGuidelinesFiles.length === 1) {
      formData.append('loan_guidelines_files', loanGuidelinesFiles[0]);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/upload-group`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        body: formData
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Upload failed');
      }

      const data = await response.json();
      setAnalysisResults(data.analysis_results);
      
      // Clear the files after successful upload
      setLoanApplicationFiles([]);
      setLoanGuidelinesFiles([]);
      
    } catch (error) {
      setError(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDownloadPDF = () => {
    const element = analysisResultsRef.current;
    const opt = {
      margin: 1,
      filename: 'loan-analysis-report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    html2pdf().set(opt).from(element).save();
  };

  return (
    <>
      <PaymentAlert />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Snackbar 
          open={showProfileAlert} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setShowProfileAlert(false)}
        >
          <Alert 
            severity="info" 
            action={
              <Button color="inherit" size="small" component={RouterLink} to="/profile">
                Complete Profile
              </Button>
            }
          >
            Please complete your profile information
          </Alert>
        </Snackbar>

        {error && (
          <Alert 
            severity="error" 
            onClose={() => setError(null)}
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        )}

        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom sx={{ color: 'rgb(52, 45, 153)' }}>
            Loan Application Analysis
          </Typography>
          
          <Box sx={{ mt: 4 }}>
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Upload Loan Application
              </Typography>
              <FileUploadZone
                files={loanApplicationFiles}
                setFiles={setLoanApplicationFiles}
                acceptedTypes={['.pdf', '.xml']}
                maxSize={200 * 1024 * 1024}
                multiple={false}
                maxFiles={1}
                onError={(error) => setError(error)}
              />
            </Paper>

            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Upload Loan Guidelines
              </Typography>
              <FileUploadZone
                files={loanGuidelinesFiles}
                setFiles={setLoanGuidelinesFiles}
                acceptedTypes={['.pdf', '.xml']}
                maxSize={200 * 1024 * 1024}
                multiple={false}
                maxFiles={1}
                onError={(error) => setError(error)}
              />
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 3 }}>
              <Button
                variant="contained"
                onClick={handleUpload}
                disabled={isUploading || (loanApplicationFiles.length === 0 && loanGuidelinesFiles.length === 0)}
                sx={{
                  bgcolor: 'rgb(0, 56, 255)',
                  '&:hover': {
                    bgcolor: 'rgba(0, 56, 255, 0.9)'
                  },
                  minWidth: 200
                }}
              >
                {isUploading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Analyze'
                )}
              </Button>
            </Box>

            {analysisResults && (
              <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Analysis Results
                </Typography>
                
                <Box ref={analysisResultsRef} sx={{ mt: 2 }}>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                      Application Preview:
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, whiteSpace: 'pre-wrap' }}>
                      {analysisResults.application_preview}
                    </Typography>
                  </Box>∫

                  <Box sx={{ mb: 4 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                      Guidelines Preview:
                    </Typography>
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                      {analysisResults.guidelines_preview}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  mt: 4
                }}>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadPDF}
                    sx={{
                      bgcolor: 'rgb(0, 56, 255)',
                      '&:hover': {
                        bgcolor: 'rgba(0, 56, 255, 0.9)'
                      },
                      minWidth: 200
                    }}
                  >
                    Download Report
                  </Button>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Dashboard; 