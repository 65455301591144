import React, { useState, useEffect } from 'react';
import { Box, Typography, Alert, CircularProgress } from '@mui/material';
import DocumentUpload from './DocumentUpload';
import DocumentList from './DocumentList';
import { useAuth } from '../context/AuthContext';

const DocumentManager = () => {
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { updateUserData, userData } = useAuth();

  const fetchDocuments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/list`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch documents: ${response.statusText}`);
      }

      const data = await response.json();
      setDocuments(data);
    } catch (err) {
      console.error('Error fetching documents:', err);
      setError(`Failed to load documents: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadComplete = async (uploadResponse) => {
    await fetchDocuments();
    
    if (uploadResponse?.credits_deducted) {
      setError({
        severity: 'success',
        message: `Document uploaded successfully. Credits remaining: ${uploadResponse.remaining_credits}`
      });
    }
  };

  const handleProcess = async (documentId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/${documentId}/process`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to process document');
      }

      const data = await response.json();
      await fetchDocuments();
      
      if (data.credits_deducted) {
        await updateUserData(true);
        setError({
          severity: 'success',
          message: `Document processed successfully. Credits remaining: ${data.remaining_credits}`
        });
      }
    } catch (err) {
      console.error('Error processing document:', err);
      setError({
        severity: 'error',
        message: err.message
      });
    }
  };

  const handleDelete = async (documentId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/${documentId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete document');
      }

      await fetchDocuments();
    } catch (err) {
      console.error('Error deleting document:', err);
      setError(`Failed to delete document: ${err.message}`);
    }
  };

  useEffect(() => {
    fetchDocuments();
    
    const interval = setInterval(fetchDocuments, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Documents</Typography>
      
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Available Credits: {userData?.credits || 0}
      </Typography>
      
      <DocumentUpload onUploadComplete={handleUploadComplete} />
      
      {error && (
        <Alert 
          severity={error.severity || 'error'} 
          sx={{ my: 2 }} 
          onClose={() => setError(null)}
        >
          {error.message}
        </Alert>
      )}
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ mt: 3 }}>
          {documents.length === 0 ? (
            <Alert severity="info">No documents found</Alert>
          ) : (
            <DocumentList 
              documents={documents}
              onProcess={handleProcess}
              onDelete={handleDelete}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default DocumentManager; 