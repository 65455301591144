import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format } from 'date-fns';

const FILE_TYPE_LABELS = {
  'loan_application': 'Loan Application',
  'loan_guidelines': 'Loan Guidelines'
};

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/groups`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch transactions');
        }

        const data = await response.json();
        setTransactions(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Transactions history
      </Typography>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Files</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Analysis Results</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.group_id}>
                <TableCell>
                  {format(new Date(transaction.created_at), 'MMM dd, yyyy HH:mm')}
                </TableCell>
                <TableCell>
                  {transaction.files.map((file, index) => (
                    <Typography key={index} variant="body2" sx={{ mb: 0.5 }}>
                      {file.filename} ({FILE_TYPE_LABELS[file.type] || file.type})
                    </Typography>
                  ))}
                </TableCell>
                <TableCell>
                  {transaction.analysis_results ? (
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>View Analysis</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          <Typography variant="subtitle2" color="primary" gutterBottom>
                            Application Preview:
                          </Typography>
                          <Typography variant="body2" paragraph>
                            {transaction.analysis_results.application_preview}
                          </Typography>
                          
                          <Typography variant="subtitle2" color="primary" gutterBottom>
                            Guidelines Preview:
                          </Typography>
                          <Typography variant="body2">
                            {transaction.analysis_results.guidelines_preview}
                          </Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      No analysis available
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {transactions.length === 0 && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography variant="body1" sx={{ py: 2 }}>
                    No documents uploaded yet
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Transactions; 