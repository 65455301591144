import React from 'react';
import { 
  Container, 
  Box, 
  Button, 
  Typography, 
  Paper,
  Divider 
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

const Login = () => {
  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google/login`;
  };

  const handleMicrosoftLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/microsoft/login`;
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 4,
        }}
      >
        <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            Sign in to GetSmartLoan.ai
          </Typography>
          
          <Box sx={{ mt: 3 }}>
            <Button
              fullWidth
              variant="contained"
              sx={{ 
                mb: 2,
                backgroundColor: '#4285F4',
                '&:hover': {
                  backgroundColor: '#357ABD'
                }
              }}
              onClick={handleGoogleLogin}
              startIcon={<GoogleIcon />}
            >
              Continue with Google
            </Button>
            
            <Button
              fullWidth
              variant="contained"
              sx={{ 
                backgroundColor: '#2F2F2F',
                '&:hover': {
                  backgroundColor: '#1F1F1F'
                }
              }}
              onClick={handleMicrosoftLogin}
              startIcon={<MicrosoftIcon />}
            >
              Continue with Microsoft
            </Button>
          </Box>

          <Box sx={{ mt: 3, mb: 2 }}>
            <Divider>
              <Typography variant="body2" color="text.secondary">
                By signing in, you agree to our Terms and Privacy Policy
              </Typography>
            </Divider>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Login; 