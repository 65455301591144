import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, List, ListItem, ListItemText, IconButton, ListItemIcon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const FileUploadZone = ({ 
  files, 
  setFiles, 
  acceptedTypes, 
  maxSize, 
  multiple = false, 
  maxFiles = 1,
  onError 
}) => {
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    // If we already have a file and trying to add more, show error
    if (files.length >= maxFiles) {
      onError?.('Only one file per group is allowed');
      return;
    }

    // Handle rejected files
    if (rejectedFiles.length > 0) {
      const errorMessages = rejectedFiles.map(file => {
        if (file.size > maxSize) {
          return `File ${file.file.name} is too large. Maximum size is ${maxSize / (1024 * 1024)}MB`;
        }
        return `File ${file.file.name} is not an accepted file type`;
      });
      onError?.(errorMessages.join('\n'));
      return;
    }

    // For single file upload, replace existing file
    if (!multiple) {
      setFiles([...acceptedFiles].slice(0, maxFiles));
    } else {
      // For multiple files, append but respect maxFiles limit
      const newFiles = [...files, ...acceptedFiles].slice(0, maxFiles);
      setFiles(newFiles);
    }
  }, [files, setFiles, maxSize, multiple, maxFiles, onError]);

  const removeFile = (fileToRemove) => {
    setFiles(files.filter(file => file !== fileToRemove));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedTypes.reduce((acc, type) => ({ ...acc, [type]: [] }), {}),
    maxSize,
    multiple: false, // Always set to false to handle one file at a time
  });

  return (
    <Box>
      {files.length === 0 && (
        <Box
          {...getRootProps()}
          sx={{
            border: '2px dashed',
            borderColor: isDragActive ? 'primary.main' : 'grey.300',
            borderRadius: 1,
            p: 2,
            mb: 2,
            textAlign: 'center',
            cursor: 'pointer',
            bgcolor: isDragActive ? 'action.hover' : 'background.paper',
            '&:hover': {
              bgcolor: 'action.hover'
            }
          }}
        >
          <input {...getInputProps()} />
          <Typography>
            {isDragActive
              ? 'Drop the file here'
              : 'Drag and drop a file here, or click to select'}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {`Accepted file types: ${acceptedTypes.join(', ')}`}
          </Typography>
        </Box>
      )}

      {files.length > 0 && (
        <List>
          {files.map((file, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" onClick={() => removeFile(file)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText 
                primary={file.name}
                secondary={`${(file.size / (1024 * 1024)).toFixed(2)} MB`}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default FileUploadZone; 