import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { handleApiResponse } from '../utils/apiHandler';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, logout } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(location.search);
      const accessToken = params.get('access_token');
      const refreshToken = params.get('refresh_token');
      const error = params.get('error');

      if (error) {
        console.error('OAuth Error:', error);
        navigate('/login', { replace: true });
        return;
      }

      if (accessToken && refreshToken) {
        login(accessToken, refreshToken);
        
        // Check profile status but don't force redirect
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/profile/check`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          
          await handleApiResponse(response, logout, navigate);
          
          if (response.ok) {
            const data = await response.json();
            
            // Get current URL parameters from the stored location before OAuth
            const storedLocation = localStorage.getItem('pre_oauth_location');
            const storedParams = new URLSearchParams(storedLocation || '');
            const paymentStatus = storedParams.get('payment');
            const sessionId = storedParams.get('session_id');
            
            // Clear stored location
            localStorage.removeItem('pre_oauth_location');

            // If there was a payment verification in progress, restore it
            if (paymentStatus === 'success' && sessionId) {
              navigate(`/dashboard1?payment=success&session_id=${sessionId}`, { replace: true });
            } else if (!data.needs_profile_setup) {
              navigate('/dashboard', { replace: true });
            } else {
              navigate('/profile', { state: { fromLogin: true } });
            }
          } else {
            navigate('/dashboard', { replace: true });
          }
        } catch (error) {
          console.error('Error checking profile:', error);
          navigate('/dashboard', { replace: true });
        }
      } else {
        navigate('/login', { replace: true });
      }
    };

    handleCallback();
  }, [navigate, location, login, logout]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  );
};

export default OAuthCallback; 