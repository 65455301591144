import React, { useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Box,
  Chip,
  Stack,
  Container,
  Divider,
  Menu,
  MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useAuth, CREDITS_UPDATED_EVENT } from '../../context/AuthContext';

const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated, logout, userData, updateUserData } = useAuth();
  const [creditsMenu, setCreditsMenu] = React.useState(null);
  const [localCredits, setLocalCredits] = React.useState(userData?.credits || 0);

  useEffect(() => {
    // Update local credits when userData changes
    setLocalCredits(userData?.credits || 0);

    // Listen for credit updates
    const handleCreditsUpdate = (event) => {
      setLocalCredits(event.detail.credits);
    };

    window.addEventListener(CREDITS_UPDATED_EVENT, handleCreditsUpdate);

    return () => {
      window.removeEventListener(CREDITS_UPDATED_EVENT, handleCreditsUpdate);
    };
  }, [userData]);

  const handleCreditsClick = (event) => {
    if (userData?.has_unlimited_credits) {
      setCreditsMenu(event.currentTarget);
    } else {
      navigate('/transactions');
    }
  };

  const handleCloseMenu = () => {
    setCreditsMenu(null);
  };

  const handleManageSubscription = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/create-portal-session`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        const { url } = await response.json();
        window.open(url, '_blank');
      }
    } catch (error) {
      console.error('Error creating portal session:', error);
    }
    handleCloseMenu();
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      updateUserData();
    }
  }, [isAuthenticated, updateUserData]);

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        height: '105px',
        bgcolor: 'rgb(242, 249, 254)',
        boxShadow: 'none'
      }}
    >
      <Container maxWidth="lg" sx={{ height: '100%' }}>
        <Toolbar sx={{ height: '100%', padding: '0 !important' }}>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                textDecoration: 'none',
                color: 'rgb(52, 45, 153)',
                marginRight: 3
              }}
              component={RouterLink}
              to="/"
            >
              <img
                src="https://framerusercontent.com/images/UMtStB4IBQKiIV0vjNi2fdcikcE.png"
                alt="LoanSmart AI Logo"
                style={{
                  width: '56px',
                  height: '61px',
                  marginRight: '12px'
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  color: 'rgb(52, 45, 153)'
                }}
              >
                LoanSmart AI
              </Typography>
            </Box>

            <Button
              component={RouterLink}
              to="/"
              sx={{ 
                color: 'rgb(52, 45, 153)',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(52, 45, 153, 0.08)'
                }
              }}
            >
              Home
            </Button>
          </Box>

          <Box>
            {isAuthenticated && (
              <Stack direction="row" spacing={2} alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip
                    icon={<PriceChangeIcon />}
                    label={userData?.has_unlimited_credits ? 'Unlimited' : `${localCredits} Application Evaluations`}
                    onClick={handleCreditsClick}
                    sx={{ 
                      bgcolor: 'rgba(0, 0, 0, 0.08)',
                      color: 'rgb(52, 45, 153)',
                      '& .MuiChip-icon': { 
                        color: 'rgb(52, 45, 153)',
                        transform: 'scale(1.2)'
                      },
                      fontWeight: 500,
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.12)'
                      }
                    }}
                  />
                  <Menu
                    anchorEl={creditsMenu}
                    open={Boolean(creditsMenu)}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={handleManageSubscription}>
                      <SettingsIcon sx={{ mr: 1 }} />
                      Manage Subscription
                    </MenuItem>
                    <MenuItem component={RouterLink} to="/transactions">
                      <SettingsIcon sx={{ mr: 1 }} />
                      Transactions
                    </MenuItem>
                  </Menu>
                  {!userData?.has_unlimited_credits && (
                    <Button
                      startIcon={<AddIcon />}
                      variant="outlined"
                      size="small"
                      component={RouterLink}
                      to="/pricing"
                      sx={{ 
                        color: 'rgb(0, 56, 255)',
                        borderColor: 'rgb(0, 56, 255)',
                        '&:hover': {
                          borderColor: 'rgb(0, 56, 255)',
                          backgroundColor: 'rgba(0, 56, 255, 0.08)'
                        }
                      }}
                    >
                      Add More Application Evaluations
                    </Button>
                  )}
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Button
                  sx={{ color: 'rgb(52, 45, 153)' }}
                  component={RouterLink}
                  to="/profile"
                >
                  Profile
                </Button>
                <Button
                  sx={{ 
                    color: 'rgb(52, 45, 153)',
                    borderColor: 'rgb(52, 45, 153)',
                    '&:hover': {
                      borderColor: 'rgb(52, 45, 153)',
                      backgroundColor: 'rgba(52, 45, 153, 0.08)'
                    }
                  }}
                  variant="outlined"
                  onClick={logout}
                >
                  Logout
                </Button>
              </Stack>
            )}
            {!isAuthenticated && (
              <Button
                sx={{ 
                  color: 'rgb(52, 45, 153)',
                  borderColor: 'rgb(52, 45, 153)',
                  '&:hover': {
                    borderColor: 'rgb(52, 45, 153)',
                    backgroundColor: 'rgba(52, 45, 153, 0.08)'
                  }
                }}
                variant="outlined"
                component={RouterLink}
                to="/login"
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;