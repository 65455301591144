import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Container,
  Paper,
  CircularProgress,
  InputAdornment,
  Alert
} from '@mui/material';
import { useAuth } from '../context/AuthContext';

const BUSINESS_TYPES = [
  'Mortgage Broker',
  'Lender',
  'Borrower',
  'Other'
];

const REFERRAL_SOURCES = [
  'Search Engine',
  'Facebook',
  'Instagram',
  'X/Twitter',
  'LinkedIn',
  'Referral',
  'Other'
];

// Phone number formatter
const formatPhoneNumber = (value) => {
  if (!value) return value;
  
  // Remove all non-digits
  const phoneNumber = value.replace(/[^\d]/g, '');
  
  // Format phone number as (XXX) XXX-XXXX
  if (phoneNumber.length <= 3) {
    return phoneNumber;
  }
  if (phoneNumber.length <= 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

// Phone number validator
const isValidPhoneNumber = (phone) => {
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  return phoneRegex.test(phone);
};

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const fromLogin = location.state?.fromLogin;

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    company_name: '',
    position: '',
    business_type: '',
    referral_source: '',
    needs_description: ''
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/profile/check`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setFormData({
            ...data.profile_data,
            email: userData?.email || ''
          });
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [userData]);

  const handlePhoneChange = (event) => {
    const formatted = formatPhoneNumber(event.target.value);
    if (formatted.length <= 14) { // (XXX) XXX-XXXX = 14 characters
      setFormData({ ...formData, phone_number: formatted });
      if (errors.phone_number) {
        setErrors({ ...errors, phone_number: null });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        body: JSON.stringify({
          ...formData,
          // If phone is empty string or only contains spaces, set to null
          phone_number: formData.phone_number?.trim() || null
        })
      });

      if (response.ok) {
        navigate('/dashboard', { replace: true });
      } else {
        const errorData = await response.json();
        if (errorData.detail && Array.isArray(errorData.detail)) {
          const newErrors = {};
          errorData.detail.forEach(error => {
            const field = error.loc[1];
            newErrors[field] = error.msg;
          });
          setErrors(newErrors);
        } else {
          setErrors({ submit: errorData.detail });
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setErrors({ submit: 'An error occurred while saving the profile' });
    }
  };

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
    if (errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="calc(100vh - 64px)">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      {fromLogin && (
        <Alert severity="info" sx={{ mb: 3 }}>
          Please complete your profile information. You can update these details later from your profile page.
        </Alert>
      )}
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        {errors.submit && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errors.submit}
          </Alert>
        )}
        <Typography component="h1" variant="h5" align="center" gutterBottom>
          Profile Information
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            label="First Name"
            value={formData.first_name}
            onChange={handleChange('first_name')}
            error={!!errors.first_name}
            helperText={errors.first_name}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Last Name"
            value={formData.last_name}
            onChange={handleChange('last_name')}
            error={!!errors.last_name}
            helperText={errors.last_name}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Phone Number (Optional)"
            value={formData.phone_number}
            onChange={handlePhoneChange}
            error={!!errors.phone_number}
            helperText={errors.phone_number}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">📞</InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Email"
            value={formData.email}
            disabled
            InputProps={{
              readOnly: true,
              sx: { 
                bgcolor: 'action.disabledBackground',
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'rgba(0, 0, 0, 0.6)',
                },
              },
              startAdornment: (
                <InputAdornment position="start">📧</InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Company Name"
            value={formData.company_name}
            onChange={handleChange('company_name')}
            error={!!errors.company_name}
            helperText={errors.company_name}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Position"
            value={formData.position}
            onChange={handleChange('position')}
            error={!!errors.position}
            helperText={errors.position}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Business Type"
            select
            value={formData.business_type}
            onChange={handleChange('business_type')}
            error={!!errors.business_type}
            helperText={errors.business_type}
          >
            {BUSINESS_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            fullWidth
            label="Referral Source"
            select
            value={formData.referral_source}
            onChange={handleChange('referral_source')}
            error={!!errors.referral_source}
            helperText={errors.referral_source}
          >
            {REFERRAL_SOURCES.map((source) => (
              <MenuItem key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            fullWidth
            label="Tell us about your Business Needs"
            value={formData.needs_description}
            onChange={handleChange('needs_description')}
            error={!!errors.needs_description}
            helperText={errors.needs_description}
            multiline
            rows={4}
            placeholder="Tell us about your Business Needs"
            InputProps={{
              sx: { 
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                },
              },
            }}
          />
          <Box sx={{ display: 'flex', gap: 2, mt: 3, mb: 2 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
            >
              Save Profile
            </Button>
            <Button
              onClick={() => navigate('/dashboard')}
              fullWidth
              variant="outlined"
              sx={{ 
                color: 'rgb(52, 45, 153)',
                borderColor: 'rgb(52, 45, 153)',
                '&:hover': {
                  borderColor: 'rgb(52, 45, 153)',
                  backgroundColor: 'rgba(52, 45, 153, 0.08)'
                }
              }}
            >
              Back to Dashboard
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Profile; 