import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

// Create a custom event for credit updates
export const CREDITS_UPDATED_EVENT = 'creditsUpdated';

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const isUpdating = useRef(false);
  const updateTimeout = useRef(null);
  const navigate = useNavigate();

  // Helper function to update userData and dispatch event
  const updateUserDataAndDispatchEvent = (data) => {
    setUserData(data);
    // Dispatch the event with the new credits value
    window.dispatchEvent(
      new CustomEvent(CREDITS_UPDATED_EVENT, {
        detail: { credits: data.credits }
      })
    );
  };

  const updateUserData = useCallback(async (force = false) => {
    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current);
    }

    if (isUpdating.current && !force) {
      updateTimeout.current = setTimeout(() => updateUserData(true), 1000);
      return;
    }

    if (!localStorage.getItem('access_token')) return;
    
    try {
      isUpdating.current = true;
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        updateUserDataAndDispatchEvent(data);
        setIsAuthenticated(true);
        return data;
      }
    } catch (error) {
      console.error('Error updating user data:', error);
    } finally {
      isUpdating.current = false;
    }
  }, []);

  const login = async (token) => {
    localStorage.setItem('access_token', token);
    setIsAuthenticated(true);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        updateUserDataAndDispatchEvent(data);
        
        const params = new URLSearchParams(window.location.search);
        const payment = params.get('payment');
        
        if (!data.is_profile_completed && !payment) {
          navigate('/profile', { state: { fromLogin: true } });
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const logout = useCallback(() => {
    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current);
    }
    localStorage.removeItem('access_token');
    setIsAuthenticated(false);
    setUserData(null);
    navigate('/login');
  }, [navigate]);

  // Initial auth check - only run once
  useEffect(() => {
    const initAuth = async () => {
      const token = localStorage.getItem('access_token');
      if (token) {
        await updateUserData(true);
      }
      setLoading(false);
    };

    initAuth();
    
    return () => {
      if (updateTimeout.current) {
        clearTimeout(updateTimeout.current);
      }
    };
  }, [updateUserData]);

  const value = {
    isAuthenticated,
    login,
    logout,
    userData,
    updateUserData,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);