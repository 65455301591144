import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Alert
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { handleApiResponse } from '../utils/apiHandler';
import { useAuth } from '../context/AuthContext';
import PaymentAlert from '../components/PaymentAlert';
import { useSnackbar } from '../context/SnackbarContext';

// Initialize Stripe with your public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PricingPlan = ({ name, price, description, features, priceId }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [error, setError] = useState(null);
  
  const handleSubscribe = async () => {
    try {
      // Store current location before initiating payment
      localStorage.setItem('pre_oauth_location', window.location.search);
      
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        body: JSON.stringify({
          priceId: priceId,
          success_url: `${window.location.origin}/dashboard?payment=success&session_id={CHECKOUT_SESSION_ID}`,
          cancel_url: `${window.location.origin}/pricing?payment=cancelled`
        })
      });

      await handleApiResponse(response, logout, navigate);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Payment initialization failed');
      }

      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.id
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    }
  };

  return (
    <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {error && (
        <Alert 
          severity="error" 
          onClose={() => setError(null)}
          sx={{ m: 2 }}
        >
          {error}
        </Alert>
      )}
      <Box sx={{ p: 3, flexGrow: 1 }}>
        <Typography variant="h5" component="h2" gutterBottom sx={{ color: 'rgb(52, 45, 153)', fontWeight: 600 }}>
          {name}
        </Typography>
        <Typography variant="h4" component="p" gutterBottom sx={{ fontWeight: 700 }}>
          {price}
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          {description}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <List dense>
          {features.map((feature, index) => (
            <ListItem key={index} disableGutters>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <CheckCircleOutlineIcon sx={{ color: 'rgb(52, 45, 153)' }} />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box sx={{ p: 3, pt: 0 }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubscribe}
          sx={{
            bgcolor: 'rgb(0, 56, 255)',
            '&:hover': {
              bgcolor: 'rgba(0, 56, 255, 0.9)'
            }
          }}
        >
          Get Started
        </Button>
      </Box>
    </Paper>
  );
};

const Pricing = () => {
  const { userData } = useAuth();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (userData?.has_unlimited_credits) {
      showSnackbar('You already have unlimited credits with your current subscription.', 'info');
      navigate('/dashboard');
    }
  }, [userData, navigate, showSnackbar]);

  const plans = [
    {
      name: 'Single Use Plan',
      price: '$50 per run',
      description: 'Perfect for occasional users who need quick analysis for a single application',
      features: [
        'Access to our comprehensive underwriter system for one submission',
        'No commitment required'
      ],
      priceId: 'price_single_use'
    },
    {
      name: '10-Run Pack',
      price: '$350',
      description: 'Ideal for professionals who manage multiple clients and need flexibility.',
      features: [
        'Enjoy discounted pricing with 10 analysis runs',
        'Valid for 60 days',
        '30% off our single-use pricing'
      ],
      priceId: 'price_10_pack'
    },
    {
      name: 'Unlimited Monthly Plan',
      price: '$750/month',
      description: 'The best value for high-volume users who need ongoing access.',
      features: [
        'Unlimited analysis runs each month',
        'Stay ahead with continuous updates and premium support',
        'Cancel anytime, no long-term commitment'
      ],
      priceId: 'price_unlimited'
    }
  ];

  return (
    <>
      <PaymentAlert />
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          gutterBottom
          sx={{ color: 'rgb(52, 45, 153)', fontWeight: 700 }}
        >
          Choose Your Plan
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          paragraph
          sx={{ mb: 6 }}
        >
          Select the perfect plan for your loan analysis needs
        </Typography>
        <Grid container spacing={4} alignItems="stretch">
          {plans.map((plan) => (
            <Grid item key={plan.name} xs={12} md={4}>
              <PricingPlan {...plan} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Pricing; 