import React, { useState } from 'react';
import { Button, CircularProgress, Alert } from '@mui/material';
import { Upload as UploadIcon } from '@mui/icons-material';
import { useAuth, CREDITS_UPDATED_EVENT } from '../context/AuthContext';

const DocumentUpload = ({ onUploadComplete }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { updateUserData } = useAuth();

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    setLoading(true);
    setError(null);

    const formData = new FormData();
    if (files[0]) {
      formData.append('loan_application_files', files[0]);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/upload-group`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const data = await response.json();
      
      // Immediately dispatch the credits update event
      if (data.credits_deducted) {
        window.dispatchEvent(
          new CustomEvent(CREDITS_UPDATED_EVENT, {
            detail: { credits: data.remaining_credits }
          })
        );
      }

      // Pass the complete response to parent
      onUploadComplete(data);
    } catch (err) {
      console.error('Upload error:', err);
      setError('Failed to upload document');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <input
        type="file"
        id="document-upload"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept=".pdf,.doc,.docx"
      />
      <label htmlFor="document-upload">
        <Button
          variant="contained"
          component="span"
          startIcon={loading ? <CircularProgress size={20} /> : <UploadIcon />}
          disabled={loading}
        >
          Upload Document
        </Button>
      </label>
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
    </div>
  );
};

export default DocumentUpload; 