import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Chip,
  Tooltip,
  Link
} from '@mui/material';
import { Delete as DeleteIcon, Download as DownloadIcon } from '@mui/icons-material';

const DocumentList = ({ documents, onProcess, onDelete }) => {
  const getStatusChip = (status) => {
    const statusConfig = {
      'uploaded': { label: 'Uploaded', color: 'default' },
      'processing': { label: 'Processing', color: 'warning' },
      'processed': { label: 'Processed', color: 'success' },
      'failed': { label: 'Failed', color: 'error' }
    };

    const config = statusConfig[status] || statusConfig.uploaded;
    return <Chip label={config.label} color={config.color} />;
  };

  const canDelete = (status) => {
    return status === 'uploaded' || status === 'failed';
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Filename</TableCell>
            <TableCell>Upload Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((doc) => (
            <TableRow key={doc.id}>
              <TableCell>{doc.filename}</TableCell>
              <TableCell>
                {new Date(doc.uploaded_at).toLocaleDateString()}
              </TableCell>
              <TableCell>{getStatusChip(doc.processing_status)}</TableCell>
              <TableCell>
                <Tooltip title="Download">
                  <IconButton
                    component="a"
                    href={doc.download_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                {(doc.processing_status === 'uploaded' || doc.processing_status === 'failed') && (
                  <Tooltip title="Process Document">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => onProcess(doc.id)}
                      sx={{ mx: 1 }}
                    >
                      Process
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title={canDelete(doc.processing_status) ? "Delete" : "Cannot delete processed or in-progress documents"}>
                  <span>
                    <IconButton
                      color="error"
                      onClick={() => onDelete(doc.id)}
                      disabled={!canDelete(doc.processing_status)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentList; 